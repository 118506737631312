import { graphql } from 'gatsby';
import React from 'react';

import Hat from 'components/shared/hat';
import Layout from 'components/shared/layout';
import Post from 'components/shared/post';

interface GrapqlData {
  file: {
    childMarkdownRemark: {
      frontmatter: {
        title: string;
        description: string;
        gallery?: any[];
      };
      htmlAst: string;
    };
  };
}

const AboutUs = ({ data }: { data: GrapqlData }) => {
  const {
    frontmatter: { title, gallery },
    htmlAst,
  } = data.file.childMarkdownRemark;

  const images = gallery?.map(image => ({
    img: image.src.childImageSharp.fluid,
    title: image.title,
  }));

  return (
    <>
      <Hat pageName="O nás" />
      <Layout>
        <Post htmlAst={htmlAst} galleryImages={images} title={title} />
      </Layout>
    </>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query {
    file(
      relativePath: {
        eq: "aktualne-u-nas/2021-01-09-historie-veterinář-s-r-o.md"
      }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          gallery {
            src {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                }
              }
            }
            title
          }
        }
        htmlAst
      }
    }
  }
`;
